<template>
  <nav class="site-navbar" :class="'site-navbar--' + mybarLayoutSkin">
    <div class="site-navbar__header">
      <h1 class="site-navbar__brand" @click="$router.push({ name: 'homepage' })">
        <a class="site-navbar__brand-lg" style="letter-spacing: 5px; " href="javascript:;">
			<!-- <img style="width:40px;margin-right:10px;" src="../assets/logo.png"> -->
			工程数据中台</a>
      </h1>
    </div>

    <div class="site-navbar__body clearfix">
      <el-menu mode="horizontal" text-color="#D8E2F0" active-text-color="#FFFFFF" background-color="#2E7FF0">
        <sub-menu  v-for="menu in menuList" :key="menu.menuId" :menu="menu" :dynamicMenuRoutes="dynamicMenuRoutes" ></sub-menu>

        <!--      导航右边  从设置按钮开始-->
        <el-menu-item style="position: fixed; right: 0px;box-shadow: -5px 0px 30px 0px rgba(0,0,0,0.16);" class="site-navbar__avatar " index="6">
          <el-dropdown :show-timeout="0" placement="bottom">
                  <span class="el-dropdown-link">
                    <img src="~@/assets/img/avatar.png" :alt="userName" />{{
                      userName
                    }}
                  </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>
<!--              <el-dropdown-item @click.native="switchAI()">算法平台</el-dropdown-item>-->
<!--              <el-dropdown-item @click.native="switchCloud()">智能云平台</el-dropdown-item>-->
<!--              <el-dropdown-item @click.native="switchScreen()">大屏展示</el-dropdown-item>-->
              <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>
			  <el-dropdown-item @click="$router.push({ name: 'theme' })">设置</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>

        <!-- <el-menu-item style="float:right;" index="5" @click="$router.push({ name: 'theme' })">
          <template slot="title"> 
            <icon name="shezhi" class="el-icon-setting"></icon> 
          </template>
        </el-menu-item> -->
        <!-- 弹窗, 修改密码 -->
        <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>

      </el-menu>
    </div>

    <!--      <el-menu mode="horizontal"-->
    <!--        background-color="#545c64" text-color="#fff" active-text-color="#fff">-->

    <!--        <el-menu-item class="site-navbar__brand" @click="$router.push({  name: 'homepage'})"> <a-->
    <!--            class="site-navbar__brand-lg" href="javascript:;">大数据平台</a></el-menu-item>-->

    <!-- <el-menu-item index="home" @click="$router.push({ name: 'home' })">
      <icon-svg name="shouye" class="site-sidebar__menu-icon"></icon-svg>
      <span slot="title">首页</span>
    </el-menu-item> -->
    <!-- 开始自己的菜单这部分为测试静态路由，不需要 -->
    <!-- <el-menu-item index="dataset" @click="$router.push({ name: 'dataset' })">
      <icon-svg name="shouye" class="site-sidebar__menu-icon"></icon-svg>
      <span slot="title">数据集管理</span>
    </el-menu-item> -->

    <!-- demo菜单 -->
    <!-- <el-submenu index="demo">
      <template slot="title">
        <icon-svg name="shoucang" class="site-sidebar__menu-icon"></icon-svg>
        <span>demo</span>
      </template>
      <el-menu-item index="demo-echarts" @click="$router.push({ name: 'demo-echarts' })">
        <icon-svg name="tubiao" class="site-sidebar__menu-icon"></icon-svg>
        <span slot="title">echarts</span>
      </el-menu-item>
      <el-menu-item index="demo-ueditor" @click="$router.push({ name: 'demo-ueditor' })">
        <icon-svg name="editor" class="site-sidebar__menu-icon"></icon-svg>
        <span slot="title">ueditor</span>
      </el-menu-item>
    </el-submenu> -->


    <!--
    <el-submenu index="demo">
      <template slot="title">
        <icon-svg name="shoucang" class="site-sidebar__menu-icon"></icon-svg>
        <span>demo</span>
      </template>
      <el-menu-item index="demo-echarts" @click="$router.push({ name: 'demo-echarts' })">
        <icon-svg name="tubiao" class="site-sidebar__menu-icon"></icon-svg>
        <span slot="title">echarts</span>
      </el-menu-item>
      <el-menu-item index="demo-ueditor" @click="$router.push({ name: 'demo-ueditor' })">
        <icon-svg name="editor" class="site-sidebar__menu-icon"></icon-svg>
        <span slot="title">ueditor</span>
      </el-menu-item>
    </el-submenu> -->

    <!--        <sub-menu  v-for="menu in menuList" :key="menu.menuId" :menu="menu" :dynamicMenuRoutes="dynamicMenuRoutes" >-->
    <!--        </sub-menu>-->


    <!-- 导航右边  从设置按钮开始-->
    <!--        <el-menu-item style="float:right" class="site-navbar__avatar " index="6">-->
    <!--          <el-dropdown :show-timeout="0" placement="bottom">-->
    <!--            <span class="el-dropdown-link">-->
    <!--              <img src="~@/assets/img/avatar.png" :alt="userName" />{{-->
    <!--                    userName-->
    <!--                  }}-->
    <!--            </span>-->
    <!--            <el-dropdown-menu slot="dropdown">-->
    <!--              <el-dropdown-item @click.native="updatePasswordHandle()">修改密码</el-dropdown-item>-->
    <!--              <el-dropdown-item @click.native="switchAI()">算法平台</el-dropdown-item>-->
    <!--              <el-dropdown-item @click.native="switchCloud()">智能云平台</el-dropdown-item>-->
    <!--              <el-dropdown-item @click.native="switchScreen()">大屏展示</el-dropdown-item>-->
    <!--              <el-dropdown-item @click.native="logoutHandle()">退出</el-dropdown-item>-->
    <!--            </el-dropdown-menu>-->
    <!--          </el-dropdown>-->
    <!--        </el-menu-item>-->

    <!--        <el-menu-item style="float:right" index="5" @click="$router.push({ name: 'theme' })">-->
    <!--          <template slot="title">-->
    <!--            &lt;!&ndash; <el-badge value="new"> &ndash;&gt;-->
    <!--            <icon-svg name="shezhi" class="el-icon-setting"></icon-svg>-->
    <!--            &lt;!&ndash; </el-badge> &ndash;&gt;-->
    <!--          </template>-->
    <!--        </el-menu-item>-->
    <!--        &lt;!&ndash; 弹窗, 修改密码 &ndash;&gt;-->
    <!--        <update-password v-if="updatePassowrdVisible" ref="updatePassowrd"></update-password>-->

    <!--      </el-menu>-->

  </nav>
</template>


<script>
import SubMenu from "./main-sidebar-sub-menu";
import { isURL } from "@/utils/validate";
import UpdatePassword from "./main-navbar-update-password";
import { clearLoginInfo } from "@/utils";
export default {
  data() {
    return {
      dynamicMenuRoutes: [],
      updatePassowrdVisible: false,
    };
  },
  components: {
    SubMenu,
    UpdatePassword,
  },
  computed: {
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType;
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      },
    },
    mainTabs: {
      get() {
        return this.$store.state.common.mainTabs;
      },
      set(val) {
        this.$store.commit("common/updateMainTabs", val);
      },
    },
    userName: {
      get() {
        return this.$store.state.user.name;
      },
    },
    //分隔
    mybarLayoutSkin: {
      get() {
        return this.$store.state.common.mybarLayoutSkin;
      },
    },

    menuList: {
      get() {
        return this.$store.state.common.menuList;
      },
      set(val) {
        this.$store.commit("common/updateMenuList", val);
      },
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      },
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      },
    },
  },
  watch: {
    $route: "routeHandle",
  },
  created() {
    this.menuList = JSON.parse(sessionStorage.getItem("menuList") || "[]");
    this.dynamicMenuRoutes = JSON.parse(
        sessionStorage.getItem("dynamicMenuRoutes") || "[]"
    );
    this.routeHandle(this.$route);
  },
  methods: {
    // 路由操作
    routeHandle(route) {
      if (route.meta.isTab) {
        // tab选中, 不存在先添加
        var tab = this.mainTabs.filter((item) => item.name === route.name)[0];
        if (!tab) {
          if (route.meta.isDynamic) {
            route = this.dynamicMenuRoutes.filter(
                (item) => item.name === route.name
            )[0];
            if (!route) {
              return console.error("未能找到可用标签页!");
            }
          }
          tab = {
            menuId: route.meta.menuId || route.name,
            name: route.name,
            title: route.meta.title,
            type: isURL(route.meta.iframeUrl) ? "iframe" : "module",
            iframeUrl: route.meta.iframeUrl || "",
            params: route.params,
            query: route.query,
          };
          this.mainTabs = this.mainTabs.concat(tab);
        }
        this.menuActiveName = tab.menuId + "";
        this.mainTabsActiveName = tab.name;
      }
    },

    //分隔
    // 修改密码
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },
    // 切换算法平台
    switchAI() {
      window.location.href = this.$store.state.ai_plat
    },
    // 切换云平台
    switchCloud() {
      window.location.href = this.$store.state.cloud_plat
    },
    // 切换大屏展示
    switchScreen() {
      window.location.href = this.$store.state.large_screen
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$http({
              url: this.$http.adornUrl("/sys/logout"),
              method: "post",
              data: this.$http.adornData(),
            }).then(({ data }) => {
              if (data && data.code === 0) {
                clearLoginInfo();
                window.location.href = this.$store.state.login_page
                // this.$router.push({ name: "login" });
              }
            });
          })
          .catch(() => {});
    },
  },
};
</script>

<style scoped>
.mynewnav {
  height: 50px;
  width: 100%;
  line-height: 50px;
}
.site-navbar__brand {
  display: table-cell;
  vertical-align: middle;
  width: 230px;
  height: 60px;
  margin: 0;
  line-height: 60px;
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  color: #fff;
}
/*.el-menu {*/
/*  white-space: nowrap;*/
/*  display: block;*/
/*  height: 60px;*/
/*  overflow: hidden;*/
/*}*/
/*.el-menu-item {*/
/*  float: none;*/
/*  display: inline-block;*/
/*}*/
.el-menu{
  display: flex;
  flex-wrap: nowrap!important;
}
</style>
